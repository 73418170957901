<template>
  <v-app>
    <v-main color="grey">
      <NavBar />
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
// import ContactBar from '@/components/ContactBar.vue'
import NavBar from '@/components/NavBar.vue'

export default {
  name: 'App',
  components:{
    // ContactBar,
    NavBar
  },
}
</script>

<style>
</style>