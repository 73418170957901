import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/memories',
    name: 'memories',
    component: () => import('../views/MemoriesView.vue')
  },
  {
    path: '/memories/:v',
    name: 'memoryview1',
    component: () => import('../views/MemoryView.vue')
  },
  {
    path: '/memory/:v',
    name: 'memoryview2',
    component: () => import('../views/MemoryView.vue')
  },
  {
    path: '/me/:v',
    name: 'memoryview3',
    component: () => import('../views/MemoryView.vue')
  },
  {
    path: '/memory-items',
    name: 'items',
    component: () => import('../views/ItemsOnlyView.vue')
  },
  {
    path: '/memory-items/:v',
    name: 'vendoritems',
    component: () => import('../views/ItemsOnlyView.vue')
  },
  {
    path: '/shop',
    name: 'shop',
    component: () => import('../views/ItemsView.vue')
  },
  {
    path: '/shop/:vendorcode',
    name: 'vendorshop',
    component: () => import('../views/ItemsView.vue')
  },
  {
    path: '/checkoutcomplete',
    name: 'vendorshop',
    component: () => import('../views/CheckoutView.vue')
  },
  { path: "/:pathMatch(.*)*", component: () => import('../views/404View.vue') }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
